import React from "react"

export default class Swagger extends React.Component {
  componentDidMount() {
    window.SwaggerUI({
      dom_id: "#swagger",
      url: this.props.url,
    })
  }
  render() {
    return <div id="swagger" />
  }
}
