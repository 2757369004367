import React from "react"
import SwaggerUI from "../../components/swagger"
import "swagger-ui-react/swagger-ui.css"

import Layout from "../../components/layout/site"

export default () => {
  return (
    <Layout title="API Explorer">
      <SwaggerUI url="/swagger-spec/LiquidOpenApi.json" />
    </Layout>
  )
}
